import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css"; // Import custom styles if needed
import logo from "../../assets/credfinLogo.svg";
const SourcingPartnersSection = () => {
  return (
    <div className="sourcing-partners-section my-5" id="sourcing">
      <h2 className="text-center text-white">Sourcing Partners</h2>
      <div
        className="mx-auto mb-4"
        style={{
          width: "8rem",
          height: "4px",
          backgroundColor: "#c2d72d", // Adjust the color to match the green line
        }}
      ></div>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-4 col-sm-12 col-md-6 col-12 text-center my-2">
            <div className="logo-container bg-white rounded p-4">
              <img
                src={logo}
                alt="CredFin Logo"
                className="img-fluid"
                style={{ maxWidth: "80%" }}
              />
            </div>
          </div>
          <div className="col-lg-6 my-2">
            <p style={{ color: "#FFFFFF" }}>
              At First Fiscal Services India Limited, we acknowledge the vital
              role of strategic partnerships in elevating our service offerings.
              Our platform is engineered to optimize the borrowing experience
              for users while offering a sophisticated and efficient solution
              for our sourcing partners. By partnering with us, you will gain
              access to a premier digital lending ecosystem that prioritizes
              efficiency, convenience, and security. Together, we aim to advance
              financial inclusion and support the sustained growth of businesses
              across India.
            </p>
          </div>
        </div>
        <div className="col-lg-10 mx-auto d-flex justify-content-end align-items-end p-2">
          <a
            href="https://credfin.money/"
            className="btn btn-outline-success mt-3"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://credfin.money/<i className="bi bi-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SourcingPartnersSection;
