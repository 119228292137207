import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import icon from "../assets/digitalLandingIcon.svg";
const FeaturesSection = () => {
  var content = [
    {
      img: "",
      title: "User Friendly Interface",
      subtitle:
        "The app’s intuitive design simplifies the loan application process, ensuring that users can easily navigate and complete their applications.",
    },
    {
      img: "",
      title: "Secure Document Submission",
      subtitle:
        "Facilitates electronic submission of documents with enhanced privacy and compliance measures, ensuring the security of sensitive information.",
    },
    {
      img: "",
      title: "Real-Time Application Tracking",
      subtitle:
        "Allows borrowers to monitor their loan application status instantly, providing transparency and keeping them informed throughout the process.",
    },
    {
      img: "",
      title: "Personalized Loan Offers",
      subtitle:
        "Delivers customized loan options based on the borrower’s financial profile, enhancing the relevance of the offers presented.",
    },
    {
      img: "",
      title: "Instant Approval Decisions ",
      subtitle:
        "Utilizes automated underwriting for fast approval decisions, minimizing wait times and expediting the loan process.",
    },
    {
      img: "",
      title: "Electronic Signatures",
      subtitle:
        "Enables digital signing of loan agreements, streamlining the process and eliminating the need for physical paperwork.",
    },
  ];
  return (
    <div className="container text-center my-5" id="features">
      <h2 className="">Features of Digital Lending App</h2>
      <div
        className="mx-auto mb-4"
        style={{
          width: "13rem",
          height: "4px",
          backgroundColor: "#c2d72d",
        }}
      ></div>
      <div className="w-100 row justify-content-center">
        {content.map((item) => {
          return (
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="d-flex align-items-start">
                <div className="">
                  <img
                    src={icon}
                    alt="User Friendly Interface"
                    style={{ width: "2rem", height: "2rem" }}
                  />
                </div>
                <div className="feature-content text-start ms-3">
                  <h5>{item.title}</h5>
                  <p>{item.subtitle}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FeaturesSection;
