import React from "react";
import "./styles.css";
import mobileApp from "../../assets/mobileApp.svg";
import steps from "../../assets/steps.svg";
function IntroCrefin() {
  return (
    <div className="container" id="intro">
      <div className="d-flex flex-wrap justify-content-center align-items-center my-2">
        <div className="col-md-7">
          <h3 style={{ color: "#023047", fontWeight: "bold" }}>
            Access Your Loan Account on the Go
          </h3>
          <h5 style={{ color: "#A2BD00" }}>
            Introducing the Credfin Loan Mobile App
          </h5>
          <p>
            Managing your finances has never been easier. With the Credfin Loan
            Mobile App, you can access your loan account anytime, anywhere.
            Taking a short-term personal loan is simple—just follow these four
            easy steps:
          </p>
          {/* <div className="row mt-4">
            <div className="col-md-3">
              <div className="circle">1</div>
              <p>Download the App</p>
              <p>Get the Credfin Loan Mobile App from the Play Store</p>
            </div>
            <div className="col-md-3">
              <div className="circle">2</div>
              <p>Sign Up</p>
              <p>Create your account with a few basic details</p>
            </div>
            <div className="col-md-3">
              <div className="circle">3</div>
              <p>Apply for a Loan</p>
              <p>Choose your loan amount and submit your application</p>
            </div>
            <div className="col-md-3">
              <div className="circle">4</div>
              <p>Receive Funds</p>
              <p>Get the money directly into your bank account</p>
            </div>
          </div>
           */}
          <div>
            <img
              src={steps}
              style={{ width: "90%", height: "auto", objectFit: "cover" }}
              alt=""
              srcset=""
            />
          </div>
          <a
            href="https://play.google.com/store/apps/details?id=com.credfin.money"
            target="_blank"
            className="buttonapplynow"
          >
            <button className="btn btn-success mt-4">Apply Now →</button>
          </a>
        </div>
        <div className="col-md-3 d-flex justify-content-center align-items-center my-2">
          <img src={mobileApp} style={{ width: "70%" }} alt="" srcset="" />
        </div>
      </div>
    </div>
  );
}

export default IntroCrefin;
