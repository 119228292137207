import React from "react";
import vision from "../assets/vision.svg";
import mission from "../assets/mission.svg";
function WhatDrivesUs() {
  return (
    <section className="py-2" id="drive">
      <div className="container">
        <h2 className="text-center">What Drives Us</h2>
        <div
          className="mx-auto"
          style={{
            width: "120px",
            height: "4px",
            backgroundColor: "#c2d72d", // Adjust the color to match the green line
          }}
        ></div>
        <div className="row my-4">
          <div className="col-md-6 my-2">
            <div
              className="card shadow rounded-3 border-0"
              style={{ minHeight: "250px" }}
            >
              <div className="card-body text-center d-flex flex-column justify-content-center align-items-center">
                <img src={vision} alt="" srcset="" />
                <h5 className="card-title">Vision</h5>
                <p className="card-text">
                  To Become India's Largest And Most Efficient Mid-Market
                  Lender, Empowering Enterprises With The Financial Support They
                  Need To Thrive.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 my-2">
            <div
              className="card shadow rounded-3  border-0"
              style={{ minHeight: "250px" }}
            >
              <div className="card-body text-center d-flex flex-column justify-content-center align-items-center">
                <img src={mission} alt="" srcset="" />
                <h5 className="card-title">Mission</h5>
                <p className="card-text">
                  To Provide Debt Solutions That Fuel The Growth Of Mid-Market
                  And Next-Gen Enterprises With Unmatched Speed, Convenience,
                  And Efficiency. Our Goal Is To Significantly Enhance Credit
                  Flow By FY 26, Driving The Success Of Numerous Enterprises.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhatDrivesUs;
