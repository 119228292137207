import logo from "../assets/logo.svg";
export default function NavBar() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }
  return (
    <nav
      className="navbar navbar-expand-lg p-0"
      data-bs-theme="dark"
      style={{ backgroundColor: "rgba(42, 108, 146, 0.7)" }}
    >
      <div className="container-fluid">
        <a
          className="navbar-brand d-flex flex-column align-items-center justify-content-center"
          href="#"
          onClick={() => scrollToTop()}
        >
          <img src={logo} alt="" style={{ width: "3rem", height: "3rem" }} />
          <h5 style={{ color: "#023047" }}>First Fiscal</h5>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mb-2 mb-lg-0 col-lg-8 col-sm-12 col-12 col-md-10 justify-content-between">
            <li className="nav-item">
              <a
                className="nav-link active active"
                aria-current="page"
                onClick={() => scrollToSection("about")}
              >
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link active"
                onClick={() => scrollToSection("products")}
              >
                Products
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link active"
                onClick={() => scrollToSection("EC")}
              >
                EMI Calculator
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link active"
                onClick={() => scrollToSection("sourcing")}
              >
                Sourcing Partners
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link active"
                onClick={() => scrollToSection("contactUs")}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
