import React from "react";
import "./styles.css";
function ExploreOffering() {
  return (
    <section
      className="offerings-section d-flex justify-content-center align-items-center"
      id="offering"
    >
      <div className="Explore-card col-lg-6 col-md-8 col-sm-10 col-11 text-center p-5">
        <h2 className="font-weight-bold text-white">Explore Our Offerings</h2>
        <div
          className="mx-auto mb-4"
          style={{
            width: "10rem",
            height: "4px",
            backgroundColor: "#c2d72d", // Adjust the color to match the green line
          }}
        ></div>
        <p style={{ color: "#023047" }}>
          Discover how First Fiscal Services India Limited can help you achieve
          your financial aspirations. Whether you're looking for investment
          opportunities, loan assistance, or comprehensive financial planning,
          we have the expertise and resources to guide you every step of the
          way.
        </p>
      </div>
    </section>
  );
}

export default ExploreOffering;
