import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ContactUs.css";
import addressLogo from "../../assets/addressLogo.svg";
import emailLogo from "../../assets/emailLogo.svg";

const ContactUs = () => {
  return (
    <div
      className="contact-us-section d-flex align-items-center justify-content-center text-white"
      id="contactUs"
    >
      <div className="container">
        <div className="row text-center">
          <div className="col-12 mb-4">
            <h2 className="font-weight-bold">Contact Us</h2>
            <div
              className="mx-auto mb-4"
              style={{
                width: "7rem",
                height: "4px",
                backgroundColor: "#c2d72d", // Adjust the color to match the green line
              }}
            ></div>
            <p>
              We are here to support you on your financial journey. Reach out to
              us at:
            </p>
          </div>
        </div>
        <div className="row justify-content-around align-items-center">
          <div className="col-sm-7 col-12 col-lg-3 col-md-5 p-0 d-flex justify-content-start align-items-center my-2">
            <div className="col-md-3 mx-2">
              <img
                src={addressLogo}
                style={{ width: "4rem", height: "4rem", objectFit: "cover" }}
                alt=""
                srcset=""
              />
            </div>
            <div className="col-md-8">
              <p>Registered Office:</p>
              <p>3605, Street No-8, Vishwas Nagar, Shahdara, Delhi - 110032</p>
            </div>
          </div>

          <div className="col-sm-7 col-12 col-lg-3 col-md-5 p-0 d-flex justify-content-start align-items-center my-2">
            <div className="col-md-3 mx-2">
              <img
                src={emailLogo}
                style={{ width: "4rem", height: "4rem", objectFit: "cover" }}
                alt=""
                srcset=""
              />
            </div>
            <div className="col-md-8">
              {/* <p>Email:</p>
              <p>ajay.kanjalia@gmail.com</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
