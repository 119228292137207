import React, { useEffect, useRef, useState } from "react";
import Slider from "@mui/material/Slider";
import "./emiCalculator.css";

export default function EmiCalculator() {
  const [value, setValue] = React.useState(10000);
  const [value2, setValue2] = React.useState(10);
  const [value3, setValue3] = React.useState(1);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [emi, setEmi] = useState(0);
  const [emiTab, setEmiTab] = useState("M");

  let widths = window.innerWidth;

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    // event.target.value = event.target.value.replace("₹ ", "");
    setValue(event.target.value);
  };
  const handleSliderChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const handleInputChange2 = (event) => {
    // if (event.target.value < 10) {
    //   return;
    // }
    setValue2(event.target.value);
  };
  const handleSliderChange3 = (event, newValue) => {
    setValue3(newValue);
  };

  const handleInputChange3 = (event) => {
    setValue3(event.target.value === "" ? 0 : Number(event.target.value));
  };

  // let totalInterest =
  //   (Number(value ?? "0") * (1 + Number(value2 ?? "0")) * (value3 / 12)) / 200;
  // let TotalAmount = Number(value ?? "0") + totalInterest;
  // let Emi = TotalAmount / value3;
  useEffect(() => {
    setResultValues();
  }, []);

  useEffect(() => {
    let timer;

    if (value2 < 10 || value2 > 30) {
      timer = setTimeout(() => {
        setValue2(10);
        setResultValues();
      }, 3000);
    } else if (value < 10000 || value > 200000) {
      timer = setTimeout(() => {
        setValue(10000);
        setResultValues();
      }, 3000);
    } else if ((value3 < 1 || value3 > 24) && emiTab === "M") {
      timer = setTimeout(() => {
        setValue3(1);
        setResultValues();
      }, 3000);
    } else if ((value3 < 1 || value3 > 2) && emiTab === "Y") {
      timer = setTimeout(() => {
        setValue3(1);
        setResultValues();
      }, 3000);
    } else {
      setResultValues();
    }

    // Calculate the interest, amount, and EMI

    // Cleanup the timeout if value2 changes before the 5 seconds are up
    return () => clearTimeout(timer);
  }, [value, value2, value3, emiTab]);

  const setResultValues = () => {
    let value3Temp =
      emiTab == "M" ? Number(value3 ?? "0") / 12 : Number(value3 ?? "0");
    const calculatedInterest =
      (Number(value ?? "0") * (1 + Number(value2 ?? "0")) * value3Temp) / 200;
    setTotalInterest(calculatedInterest.toFixed(2));

    const calculatedAmount = Number(value ?? "0") + calculatedInterest;
    setTotalAmount(calculatedAmount.toFixed(2));

    const calculatedEmi = calculatedAmount / value3Temp;
    setEmi(calculatedEmi);
  };

  return (
    <>
      <div className="calculator pt-5 pb-5" id="EC">
        <div className="m-0 p-0">
          <div className="">
            <div className="col">
              <div className="heading">
                <h3 className="fw-bold text-center">EMI CALCULATOR</h3>
                <div
                  className="mx-auto mb-4"
                  style={{
                    width: "7rem",
                    height: "4px",
                    backgroundColor: "#c2d72d", // Adjust the color to match the green line
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="row w-100 m-0 p-0" id="rowsfirst">
            <div
              className="col-lg-7 col-xs-12 px-2 py-2"
              style={{ backgroundColor: "#DDE6AB" }}
            >
              <div className="sliders col-lg-6 col-md-8 col-sm-11 col-11 mx-auto">
                <div className="LoanAmount pt-5">
                  <div className="row">
                    <div className="col">
                      <h5>Loan Amount</h5>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="currency-symbol">₹</span>
                      <input
                        type="number"
                        className="form-control-plaintext loan-amount-input"
                        id="loanAmount"
                        value={value}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="underline"></div>
                  </div>
                </div>
                <div className="SelectEmiOption pt-3">
                  <div className="row">
                    <div className="col">
                      <h5>Loan Terms</h5>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        type="number"
                        className="form-control-plaintext loan-amount-input"
                        id="emiRate"
                        value={value3}
                        style={{ width: "25%" }}
                        onChange={handleInputChange3}
                      />
                      <span
                        className="p-2 cursor-pointer"
                        style={{
                          fontSize: "8px",
                          fontWeight: emiTab === "M" ? "bold" : "500",
                          backgroundColor:
                            emiTab === "M" ? "#023047" : "#DDE6AB",
                          color: emiTab === "M" ? "#ffffff" : "black",
                          borderRadius: 10,
                        }}
                        onClick={() => setEmiTab("M")}
                      >
                        Months
                      </span>
                      <span
                        className="p-2 cursor-pointer"
                        style={{
                          fontSize: "8px",
                          fontWeight: emiTab === "Y" ? "bold" : "500",
                          backgroundColor:
                            emiTab === "Y" ? "#023047" : "#DDE6AB",
                          color: emiTab === "Y" ? "#ffffff" : "black",
                          borderRadius: 10,
                        }}
                        onClick={() => setEmiTab("Y")}
                      >
                        Years
                      </span>
                    </div>
                    <div className="underline"></div>
                  </div>
                </div>
                <div className="InterestRate pt-3">
                  <div className="row">
                    <div className="col">
                      <h5>Interest Rate</h5>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        type="number"
                        className="form-control-plaintext loan-amount-input"
                        id="interestRate"
                        value={value2}
                        style={{ width: "25%" }}
                        onChange={handleInputChange2}
                      />
                      <span className="" style={{ fontWeight: "bold" }}>
                        %
                      </span>
                    </div>
                    <div className="underline"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-xs-12 p-0 text-white">
              <div className="emi p-0 h-100">
                <div
                  className="d-flex flex-column m-0 p-0 p-4"
                  style={{ height: "60%", backgroundColor: "#A2BD00" }}
                >
                  <div className="col-12">
                    <div className="emivalue">
                      <h6>Monthly Installment:</h6>
                      <h3>₹ {emi.toFixed(2)}</h3>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="calculations">
                      <div className="mt-3">
                        <div className="col">
                          <h6>Principal Amount</h6>
                        </div>
                        <div className="col">
                          <h5 className="values">₹ {value}</h5>
                        </div>
                      </div>

                      <hr />
                    </div>
                  </div>
                </div>
                <div
                  className="col p-4"
                  style={{ height: "40%", backgroundColor: "#023047" }}
                >
                  <div className="row">
                    <div className="col">
                      <h5 className="h5">Total Amount</h5>
                    </div>
                    <div className="col">
                      <h5 className="h5values">{totalAmount}</h5>
                    </div>
                  </div>

                  <div className="row mt-4 mb-4 px-1 col-lg-6 col-md-6 col-sm-8 col-12">
                    {/* <button className="buttonapply">Apply Now</button> */}
                    <a
                      href="https://play.google.com/store/apps/details?id=com.credfin.money"
                      target="_blank"
                      className="buttonapplynow"
                    >
                      Apply Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
