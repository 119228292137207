import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import Home from "./screens/home.jsx";

function App() {
  return (
    <Router>
      <Routes>
        <Route index exact path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
